/*.wrapperLoaders{*/
/*    position: relative;*/
/*    top: 0;*/
/*    right: -130%;*/
/*    animation:al 4s linear ;*/
/*    animation-fill-mode:forwards;*/
/*    pointer-events: auto;*/
/*}*/

/*@keyframes al {*/
/*    0%{    right: -130%;}*/
/*    70%{    right: -130%;}*/
/*    100%{    right: 0;}*/
/*}*/


.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}


.modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.modalContent p{
    margin-bottom: 15px;
    font-size: 16px;
    color: var(--color-text-in-light);
}

.modalContent h2 {
    margin-bottom: 15px;
    font-size: 20px;
    color: var(--color-text-in-light);
}


.modalContent input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}


.modalContent button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;

    cursor: pointer;
    font-size: 16px;
}

.modalContent button:first-of-type {
    background: var(--color-gray-fill);
    color: var(--color-orange-accent);
    margin-right: 10px;
}

.modalContent button:last-of-type {
    background: var(--color-orange-accent);
    color: var(--color-text-in-dark);
}

.modalContent button:hover {
    opacity: 0.9;
}
