.modalAll{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: var(--color-black-body);
    top: 0;
    left: 0;

    z-index: 4;
    overflow: scroll;
    /*padding: 20px 15px;*/
    pointer-events: auto;
    box-sizing: border-box;
}

.back{
    position: absolute;
    background-color: var(--color-gray-fill);
    right: 12px;
    z-index: 2;
    border-radius: 16px;
    top: 12px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alert{
    position: fixed;

    width: 100%;
    bottom: 129px;
    height: 50px;
    font-weight: 600;
    color: var(--color-text-in-light);
    z-index: 3;
    border-radius: 20px 20px 0 0;
    border-top: 1px solid var(--color-text-in-light);
    background-color: var(--color-back-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;

}

.priceDisc{
    color: #8E919F;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    margin-right: 6px;
    position: relative;
}

.wrapIcon{
    position: absolute;
    left: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    background-color: var(--color-gray-fill);
}




.priceDisc:before{
    content: "";
    border-bottom: 2px solid #8E919F;
    position: absolute;
    width: 100%;
    height: 50%;
    transform: rotate(-12deg);
}

.wrapperItem{
    display: flex;
    flex-direction: column;
    padding: 12px 16px 0;
    margin-bottom: 8px;
    border-radius: 0 0 20px 20px;
    background-color: var(--color-back-primary);
    position: relative;
}

.wrapperImg{
    width: 100%;
    height: 280px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}
.noFoto{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--color-gray-fill);
    color: var(--color-text-secondary);
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03px;
}
.wrapperImg img{
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.title{
    font-size: 20px;
    color: var(--color-text-in-light);
    margin-top: 20px;
    margin-bottom: 8px;
    font-weight: 700;
}

.price {
    font-size: 20px;
    font-weight: 700;
    border-radius: 8px;
    max-width: max-content;
    color: var(--color-orange-accent);
    position: relative;
    box-sizing: border-box;
    padding: 4px 8px;
    z-index: 2;
    background-color: var(--color-gray-fill);
}

.priceDisc{
    color: #8E919F;
    font-size: 20px;
    font-weight: 500;
    position: relative;
}
.description{
    color: var(--color-text-secondary);
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 20px;
    padding-top: 20px;
}

.checkboxTitle{
    padding-top: 20px;
    color: var(--color-text-in-light);
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 16px;
    border-top: 1px solid var(--color-line);
    width: 100%;
}

.wrapperButton{
    width: calc(100%);

    position: fixed;
    bottom: 70px;
    left: 0;
    background-color: var(--color-back-primary);
    z-index: 3;
    padding: 8px 16px;
    box-sizing: border-box;
}
.checkbox_wrapper{
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-line);
    margin-bottom: 20px;
}
.checkbox-custom {
    opacity: 0;
    position: absolute;
}

.checkbox-custom, .checkbox-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

.checkbox-custom + .checkbox-custom-label:before {
    content: '';
    background: #fff;
    border-radius: 5px;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 5px;
    border: solid blue;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    border-radius: 0px;
    margin: 0px 15px 5px 5px;
}

.gramm{
    margin: 0px 0 20px;
    font-size: 14px;
    font-weight: 600;
    display: block;
    color: var(--color-text-secondary);
}

.gramm p:first-of-type{
    color: var(--color-text-in-light);
}

.gramm p{
    margin: 0;
}

.Button{
    display: flex;
    padding: 14px 18px;
    background-color: var(--color-orange-accent);
    color: #fff;
    border-radius: 55px;
    line-height: 18px;
    font-size: 14px;
    font-weight: 700;
    align-items: center;
    justify-content: center;
}

.disbaled{
    pointer-events: none;
    background-color: #797979;
}

.orderButtons{
    display: flex;
    width: 100%;
}

.wrapperNavLink a{
  text-decoration: none;
}

.ButtonGreen{
    display: flex;
    padding: 8px 18px;
    background-color: var(--color-success);
    color: #fff;
    border-radius: 55px;
    font-size: 14px;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ButtonGreen p{
    margin: 0;
}
.ButtonGreen span{
  font-size: 10px;
    font-weight: 500;
    line-height: 12px;


}

.wrapperNavLink{
    width: 100%;
    padding-right: 8px;
    box-sizing: border-box;
}

.buttonGray{
    display: flex;
    justify-content:space-between;
    min-width: 113px;
    background-color:var(--color-gray-fill) ;
    overflow: hidden;
    border-radius: 55px;
    align-items: center;
    color: var(--color-text-in-light);
    font-size: 14px;
    font-weight: 600;
    width: 30%;
}

.menuSale{
    background-color: #F3F2F8;
    padding: 5px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 15px;
    border-radius: 10px;
}

.wrapperBtn{
    padding:20px;
}

.homepageSale{
    height: 100px;
}


.minus{
    margin-left: 0px;
    margin-top: 0px;
    box-shadow: 0 0 0 0;
    border: 0;
    width: 31px;
    text-align: right;
    padding: 0;
    height: 100%;
    border-radius: 0;
    background-color: var(--color-gray-fill);
}

.plus{
    border:0;
    padding: 0;
    text-align: left;
    width: 31px;
    height: 100%;
    margin-right: 0px;
    background-color: var(--color-gray-fill);
}
.plus svg{
    width: 16px;
    height: 16px;
}
.plus svg path{
   stroke: var(--color-orange-accent);
}


.minus svg{
    width: 16px;
    height: 16px;
}
.minus svg path{
   stroke: var(--color-orange-accent);
}

.discount{
    color: var(--color-orange-accent);
}
.imageFly {
    animation: fly-to-basket 60s forwards;
    position: absolute;
    width: 100%;
    top: 10px;
    left: 10px;
    z-index: 1000;
}

/* Новый стиль для анимации */
@keyframes fly-to-basket {
    0% {
        transform: scale(1) translate(0, 0);
        opacity: 1;
    }
    100% {
        transform: scale(0.1) translate(calc(100vw - 50px), calc(100vh - 50px));
        opacity: 0;
    }
}


